
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import Logo from '../components/Logo'
import Parse from '../vendor/Parse'
const { useState } = React;

export default () => {

  const location = useLocation();
  const qs = new URLSearchParams(location.search)
  const token = qs.get('o')
  const _email = qs.get('e')
  const [email, setEmail] = useState(_email || '')
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('')


  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <Logo />
                  <h3 className="mb-0">Reset password</h3>
                </div>
                <Form>
                  {
                    !token && <Form.Group id="email" className="mb-4">
                      <Form.Label>Your Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control autoFocus value={email} required type="email" placeholder="example@company.com"
                          onChange={(e) => {
                            setEmail(e.target.value)
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  }

                  {
                    token && <><Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          value={newPassword}
                          required
                          type="password"
                          placeholder="Password"
                          onChange={(e) => {
                            setNewPassword(e.target.value)
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                      <Form.Group id="confirmPassword" className="mb-4">
                        <Form.Label>Confirm Password</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            value={confirmPassword}
                            required
                            type="password"
                            placeholder="Confirm Password"
                            onChange={(e) => {
                              setConfirmPassword(e.target.value)
                            }}
                          />
                        </InputGroup>
                      </Form.Group></>
                  }
                  <Button variant="primary" onClick={async () => {
                    if (token) {
                      if (confirmPassword === newPassword) {
                        await Parse.Cloud.run('updatePassword', {
                          otp: token,
                          password: newPassword,
                          email
                        })
                        navigate('/')
                      }
                    } else {
                      Parse.Cloud.run('requestPasswordLink', {
                        email
                      })
                    }
                  }} className="w-100">
                    Reset password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
